<template>
	<div class="message-list mess-table">
		<CommonTable
			:total-items="totalItems"
			:total-pages="totalPages"
			:recent-filter="recentFilters"
			:show-filters="false"
			@create="goToCreateForm"
			@search="search"
		>
			<template #headers>
				<tr>
					<th
						v-for="(header, index1) in headers"
						:key="`${header.title}-${index1}`"
						scope="col"
						:class="`${header.class}`"
						>{{ header.title }}</th
					>
				</tr>
			</template>
			<template #body>
				<template v-if="messages && messages.length">
					<tr
						v-for="(message, index2) in messages"
						:key="`${message.id}-${index2}`"
						@click="goToMess(message.id)"
						@click.right="goToMessNewTab(message.id)"
						@click.middle="goToMessNewTab(message.id)"
					>
						<td scope="row" class="table-no mess-table__td-fixed">{{ getNo(index2) }}</td>
						<td scope="row" class="mess-table__td-fixed"
							><div>{{ message.body }}</div></td
						>
						<td scope="row" class="mess-table__td-fixed">
							<div v-if="textObjects(message.customerObject)[0]">{{
								textObjects(message.customerObject)[0]
							}}</div>
							<span v-if="textObjects(message.customerObject)[1]">{{
								textObjects(message.customerObject)[1]
							}}</span>
							<span v-if="textObjects(message.customerObject)[2]">...</span>
						</td>
						<td
							v-for="(week, idWeek) in Object.values(message.setting)"
							:key="idWeek"
							scope="row"
							:class="['text-center', { 'setting-time': week }]"
							>{{ week }}</td
						>
						<td class="text-center">
							<router-link
								:to="{ name: 'UpdateMessage', params: { id: message.id } }"
								class="link-icon text-info"
							>
								<span class="prefix-input d-inline-block">
									<font-awesome-icon :icon="['fas', 'eye']" :title="$t('Action.Edit')" />
								</span>
							</router-link>
						</td>
					</tr>
				</template>
				<template v-else>
					<tr class="text-center">
						<td colspan="20" class="fit">
							{{ $t("Table.NoData") }}
						</td>
					</tr>
				</template>
			</template>
		</CommonTable>
	</div>
</template>

<script>
import { CUSTOMER_OBJECT } from "@/shared/plugins/constants"
import { GET_MESSAGES } from "./../store/action-types"
import { RESET_FILTERS_MESSAGE_LIST } from "./../store/mutation-types"
import { createNamespacedHelpers } from "vuex"
const { mapActions, mapMutations, mapState } = createNamespacedHelpers("messages")

export default {
	name: "MessageList",

	components: {},

	props: {},

	data() {
		return {
			headers: [
				{
					title: this.$t("MessageForm.No"),
					class: "fit mess-table__th-fixed",
				},
				{
					title: this.$t("MessageForm.Content"),
					class: "text-nowrap mess-table__th-fixed",
				},
				{
					title: this.$t("MessageForm.CustomerObject"),
					class: "text-nowrap mess-table__th-fixed",
				},
			],
		}
	},

	computed: {
		...mapState(["messages", "totalItems", "totalPages", "recentFilters"]),
	},

	watch: {},

	created() {
		this.setUpData()
	},

	beforeDestroy() {
		if (!this.$route.path.includes("messages")) this.RESET_FILTERS_MESSAGE_LIST()
	},

	methods: {
		...mapActions({ GET_MESSAGES }),
		...mapMutations({ RESET_FILTERS_MESSAGE_LIST }),

		setUpData() {
			let index
			for (index = 1; index <= 72; index++) {
				this.headers.push({
					title: `W ${index}`,
					class: "text-center",
				})
			}
			this.headers.push({
				title: this.$t("Action.ViewEdit"),
				class: "fit text-center",
			})
		},

		search(params) {
			this.GET_MESSAGES(params)
		},

		goToCreateForm() {
			this.$router.push({ name: "CreateMessage" })
		},

		textObjects(objects) {
			if (objects.length === Object.keys(CUSTOMER_OBJECT).length - 1)
				return [this.$t("MessageForm.All")]

			let textObj = []
			for (const obj of objects) {
				textObj.push(this.labelObject(obj))
			}
			return textObj
		},

		goToMess(messId) {
			this.$router.push({ name: "UpdateMessage", params: { id: messId } })
		},

		goToMessNewTab(messId) {
			let routeData = this.$router.resolve({
				name: "UpdateMessage",
				params: { id: messId },
			})
			window.open(routeData.href, "_blank")
		},

		labelObject(value) {
			return {
				[CUSTOMER_OBJECT.MSM]: this.$t("MessageForm.MSM"),
				[CUSTOMER_OBJECT.TRANS_WOMAN]: this.$t("MessageForm.TransWoman"),
				[CUSTOMER_OBJECT.TRANS_MAN]: this.$t("MessageForm.TransMan"),
				[CUSTOMER_OBJECT.TCMT]: this.$t("MessageForm.TCMT"),
				[CUSTOMER_OBJECT.PROSTITUTE]: this.$t("MessageForm.Prostitute"),
				[CUSTOMER_OBJECT.HETEROSEXUAL_PARTNER]: this.$t("MessageForm.HeterosexualPartner"),
				[CUSTOMER_OBJECT.OTHER]: this.$t("MessageForm.Other"),
			}[value]
		},

		getNo(no) {
			return no + 1 + (+this.recentFilters.offset || 0)
		},
	},
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";
@import "@/assets/scss/mixins";

.message-list {
	.setting-time {
		background-color: $color-summer-sky;
		color: $color-white;
	}

	tbody tr:hover .mess-table__td-fixed {
		background-color: $color-alice-blue1;
	}

	tbody tr:hover {
		cursor: pointer;
	}
}

.mess-table {
	&__th-fixed {
		background-color: $color-pale-blue !important;
		&:nth-child(1) {
			min-width: 48px;
		}
		&:nth-child(2) {
			min-width: 400px;
		}
		&:nth-child(3) {
			min-width: 150px;
		}
		&:nth-child(4) {
			min-width: 150px;
		}
	}

	&__td-fixed {
		background: $color-white;
	}

	&__td-fixed:nth-of-type(2) > div {
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&__th-fixed,
	&__td-fixed {
		position: -webkit-sticky; /* for Safari */
		position: sticky;
		text-align: left;
		z-index: 3;
		outline: 1px solid $color-hawkes-blue;
		outline-offset: -1px;

		&:nth-child(1) {
			left: 0;
			max-width: 48px;
		}
		&:nth-child(2) {
			left: 48px;
			max-width: 400px;
		}
		&:nth-child(3) {
			left: 448px;
			max-width: 150px;
		}
		&:nth-child(4) {
			left: 598px;
			max-width: 150px;
		}
	}

	th:not(.mess-table__th-fixed),
	td:not(.mess-table__td-fixed) {
		padding: 0.75rem 0.5rem 0.5rem;
		line-height: 1.2;
	}
}
</style>
